import React, { Component } from 'react';
import AdminContext from '../context/adminContext';

class EditGallery extends Component {
  render() {
    return (
      <AdminContext.Consumer>
        {context => (
          // <div className="edit-hide-gallery">
          <div className="editDelete">
            <button className="edit" onClick={this.props.galleryClick}>
              EDIT PHOTOS
            </button>
            <button className="delete" onClick={() => context.deleteGallery(this.props.gallery._id)}>
              DELETE
            </button>
          </div>
        )}
      </AdminContext.Consumer>
    );
  }
}

export default EditGallery;
