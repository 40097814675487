import React, { Component } from 'react';
import { Link } from '@reach/router';
import { categories } from '../config/constants';
import Links from './Links';

class LeftNav extends Component {
  render() {
    return (
      <nav id="left-nav">
        <div className="nav-wrapper">
          <ul className="projects">
            {categories.map(category => (
              <li key={category}>
                <Link
                  to={`/${category.toLowerCase().replace(/\/?\s+/g, '-')}`}
                  onClick={() => this.props.categoryChangeHandler(category.toLowerCase())}
                  className={
                    category.toLowerCase().replace(/\/?\s+/g, '-') == this.props.category.toLowerCase().replace(/\/?\s+/g, '-') &&
                    this.props.location == 'Main'
                      ? 'category-link category-link-selected'
                      : 'category-link'
                  }
                >
                  {category}{' '}
                </Link>
              </li>
            ))}
          </ul>
          <Links selectedLink={this.props.location} />
        </div>
      </nav>
    );
  }
}

export default LeftNav;
