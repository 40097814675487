import React, { Component } from 'react';
import { domain } from '../config/constants';

class BlogImages extends Component {
  render() {
    return (
      <div className="post-images">
        {this.props.photos.map((photo, i) => {
          return (
            <figure key={i} className="grid-image" style={photo.order ? { order: photo.order } : { order: i }}>
              <img
                className={photo.link ? 'post-image__clickable' : ''}
                src={`${domain}/uploads/news/${photo.location}`}
                alt={photo.caption ? photo.caption : `blog photo ${i}`}
              />
              {photo.caption ? <figcaption>{photo.caption}</figcaption> : null}
            </figure>
          );
        })}
      </div>
    );
  }
}

export default BlogImages;
