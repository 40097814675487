import React, { Component } from 'react';

class BlogEditButtons extends Component {
  render() {
    return (
      <div className="blog-edit-buttons">
        <button className="delete" onClick={() => this.props.deletePost(this.props.post._id)}>
          DELETE POST
        </button>
        <button className="edit" onClick={() => this.props.handleEditClick(this.props.post)}>
          EDIT POST
        </button>
      </div>
    );
  }
}

export default BlogEditButtons;
