import React, { Component } from 'react';
import { Link } from '@reach/router';

class Links extends Component {
  render() {
    return (
      <nav className="links">
        <ul>
          <li>
            <Link to="/about">
              <span
                onClick={this.props.toggleHamburgerMenu}
                className={this.props.selectedLink == 'About' ? 'selected-link no-highlight' : 'no-highlight'}
              >
                About
              </span>
            </Link>
          </li>
          <li>
            <a href="https://www.instagram.com/ayabrackett/" target="_blank" rel="noopener noreferrer">
              Instagram
            </a>
          </li>
          <li>
            <Link to="/news">
              <span
                onClick={this.props.toggleHamburgerMenu}
                className={this.props.selectedLink == 'News' ? 'selected-link no-highlight' : 'no-highlight'}
              >
                News
              </span>
            </Link>
          </li>
          <li>
            <Link to="/shop">
              <span
                onClick={this.props.toggleHamburgerMenu}
                className={this.props.selectedLink == 'Shop' ? 'selected-link no-highlight' : 'no-highlight'}
              >
                Shop
              </span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Links;
