import React, { Component } from 'react';
import AdminContext from '../context/adminContext';
import { Link } from '@reach/router';

class Breadcrumb extends Component {
  static contextType = AdminContext;

  componentDidMount() {
    this.context.toState({
      galleryName: '',
      editgalleryName: false,
    });
  }
  render() {
    return (
      <AdminContext.Consumer>
        {context => (
          <div className={'breadcrumb'}>
            {!!this.props.category && this.props.category != 'Search' && this.props.view == 'category' ? (
              <span>{this.props.category.replace('-', ' ').toUpperCase()}</span>
            ) : null}
            {this.props.view == 'gallery' &&
            !!this.props.category &&
            this.props.category != 'Search' &&
            !!this.props.gallery &&
            !!this.props.gallery.name &&
            !context.editgalleryName ? (
              this.props.category.replace('-', ' ').toUpperCase() == this.props.gallery.name.toUpperCase() ? (
                <span>{this.props.category.replace('-', ' ').toUpperCase()}</span>
              ) : (
                <div style={{ display: 'flex' }}>
                  <span>
                    <Link
                      to={`/${this.props.category.toLowerCase().replace(/\/?\s+/g, '-')}`}
                      onClick={() => context.categoryChangeHandler(this.props.category.toLowerCase())}
                      className="breadcrumb__category"
                    >
                      {this.props.category.replace('-', ' ').toUpperCase() + ' > '}
                    </Link>
                  </span>
                  <span style={{ after: require('../assets/images/edit.svg') }}>{this.props.gallery.name.toUpperCase() + ' '}</span>
                  <span>
                    <img
                      className="edit-icon breadcrumb__edit-icon"
                      src={require('../assets/images/edit.svg')}
                      alt="edit"
                      onClick={() =>
                        context.toState({
                          editgalleryName: true,
                          galleryName: this.props.gallery.name,
                        })
                      }
                      style={{ borderRadius: 'none' }}
                    />
                  </span>
                </div>
              )
            ) : this.props.view == 'gallery' &&
              !!this.props.category &&
              this.props.category != 'Search' &&
              !!this.props.gallery &&
              !!this.props.gallery.name &&
              !!context.editgalleryName &&
              this.props.category.replace('-', ' ').toUpperCase() != this.props.gallery.name.toUpperCase() ? (
              <div style={{ display: 'flex' }}>
                <span>{this.props.category.replace('-', ' ').toUpperCase() + ' > '}</span>
                <input
                  className="edit-gallery-name"
                  type="text"
                  name="gallery-name"
                  value={context.galleryName}
                  onChange={e => context.toState({ galleryName: e.target.value })}
                ></input>
                <div className="cancel-submit">
                  <input
                    type="button"
                    name="gallery-name-cancel"
                    className="cancel-button"
                    value="Cancel"
                    onClick={() => context.toState({ editgalleryName: false })}
                  />

                  <input
                    type="button"
                    name="submitPhoto"
                    className="submit-button"
                    value="Submit"
                    onClick={() => context.updateGalleryName(context.galleryName, this.props.gallery._id, this.props.category)}
                  />
                </div>
              </div>
            ) : this.props.view == 'gallery' &&
              !!this.props.category &&
              this.props.category != 'Search' &&
              !!this.props.gallery &&
              !!this.props.gallery.name &&
              !!context.editgalleryName &&
              this.props.category.replace('-', ' ').toUpperCase() == this.props.gallery.name.toUpperCase() ? (
              <span>{this.props.category.replace('-', ' ').toUpperCase()}</span>
            ) : null}
          </div>
        )}
      </AdminContext.Consumer>
    );
  }
}

export default Breadcrumb;
