import React, { Component } from 'react';
import LogoutButton from './LogoutButton';

class Search extends Component {
  render() {
    return (
      <form className="search-container" onSubmit={this.props.search}>
        <div className="search-wrapper">
          <input
            type="text"
            className="search"
            placeholder="Find something"
            onChange={this.props.handleSearchInput}
            value={this.props.searchInput}
          ></input>

          <button type="submit" onClick={this.props.search} aria-labelledby="submit button" name="search">
            <img src={require('../assets/images/search.svg')} alt="search icon"></img>
          </button>
        </div>
        <LogoutButton />
      </form>
    );
  }
}

export default Search;
