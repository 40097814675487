import React, { Component } from 'react';

class MobileInfo extends Component {
  render() {
    return (
      <div className={this.props.mobileInfo ? 'mobile-info' : 'mobile-info hide'} onClick={this.props.toggleMobileInfo}>
        <span>{this.props.photo.caption}</span>
      </div>
    );
  }
}

export default MobileInfo;
