import React, { Component } from 'react';
import NewsContext from '../context/newsContext';

export class PostPhotoEdit extends Component {
  static contextType = NewsContext;
  state = {
    caption: '',
  };

  submitPhotoEdit = () => {
    let editPost = this.context.editPost;
    let photo = editPost.photos.find(photo => {
      return photo == this.props.photo;
    });
    photo = Object.assign(photo, this.state);
    console.log(photo);

    this.context.toState({ editPhoto: {}, editPost });
    console.log(this.context.editPost);
  };

  render() {
    return (
      <NewsContext.Consumer>
        {context => (
          <div className="postPhotoEdit">
            <input
              type="text"
              name="caption"
              value={context.editPhoto.caption}
              placeholder={'caption (optional)'}
              onChange={e => context.handlePhotoEditInputChange(context.editPhoto, 'caption', e.target.value)}
            ></input>
            <div className="cancel-submit">
              <input
                type="button"
                name="cancel"
                className="cancel-button"
                value="Cancel"
                onClick={() => context.cancelPhotoEdit(context.editPhoto)}
                // onClick={() => this.props.toState({ editPhoto: {} })}
              />

              <input
                type="button"
                name="submitPhoto"
                className="submit-button"
                value="Submit Change"
                // onClick={() => this.props.toState({ editPhoto: {} })}
                onClick={() => context.submitPhotoEdit(context.editPhoto)}
              />
            </div>
          </div>
        )}
      </NewsContext.Consumer>
    );
  }
}

export default PostPhotoEdit;
